
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function Contact(){

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_tpgd3fe', 'template_ypzc0ph', form.current, 'G-RCQVurkIhc1OxNp')
      .then((result) => {
          console.log(result.text);
          e.target.reset();
          alert("Email successfully sent!")
      }, (error) => {
          console.log(error.text);
      });
    }






  return (
  

    <section name='contact' className="w-full h-screen bg-[#000000] flex flex-col justify-center items-center contact">
      <div class="w-2/4 my-auto ">

        <form ref={form} onSubmit={sendEmail} className="space-y-8">
            <div className='pb-8'>
                 <p className='text-4xl font-bold inline border-b-4 border-sky-500 text-gray-300'>Contact</p>
                 <p className='text-gray-300 py-4'>Feel free to send me an email!</p>
            </div>
            <div>
                <input type="text"  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Your name" name="from_name" required/>
            </div>
            <div>
                <input type="email" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Your email" name="from_email" required/>
            </div>
            <div class="sm:col-span-2">
                <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Your message" name="message" ></textarea>
            </div>
            <button className='text-white border-2 hover:bg-sky-500 hover:border-sky-500 px-4 py-3 my-8 mx-auto flex items-center' type="submit" value="send" >Let's Collaborate</button>
        </form>
      </div>
  </section>



  )
}

