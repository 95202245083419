
import TDL from '../assets/websitepics/ToDoList.png'
import CT from '../assets/websitepics/CryptoTrackr.png'
import HOF from '../assets/websitepics/HouseofFumo.png'
import DR from '../assets/websitepics/DiceRoll.png'
import Twimba from '../assets/websitepics/Twimba.png'
import PG from '../assets/websitepics/PasswordGenerator.png'
import LC from '../assets/websitepics/LinkedinClone.png'

export const data=[
    {
        id:1,
        name:"Linkedin Clone (Solo + Tutorial)",
        image: LC,
        github:"https://github.com/ACEUS99/my-linkedin-clone",
        live:"https://aaman-linkedin-clone.netlify.app/",
    },
    
    {
        id:2,
        name:"Crypto Trackr (Solo)",
        image: CT,
        github:"https://github.com/ACEUS99/Crypto-Trackr",
        live:"https://cryptotrackr.netlify.app",
    },
    
    {
        id:3,
        name:"To-do List (Solo)",
        image: TDL,
        github:"https://github.com/ACEUS99/To-Do-List",
        live:"https://tdl-todolist.netlify.app",
    },
    {
        id:4,
        name:"House of Fumo (Solo)",
        image: HOF,
        github:"https://github.com/ACEUS99/House-of-Fumo",
        live:"https://house-of-fumo.netlify.app",
    },
    {
        id:5,
        name:"Random Password Generator (w/Scrimba)",
        image: PG,
        github:"https://github.com/ACEUS99/Random-Password-Generator",
        live:"https://rpg-scrimba.netlify.app",
    },
    {
        id:6,
        name:"Twimba (Twitter Clone w/Scrimba)",
        image: Twimba,
        github:"https://github.com/ACEUS99/Twimba",
        live:"https://twitter-scrimba.netlify.app",
    },
    {
        id:7,
        name:"Dice Roll Game (w/Scrimba)",
        image: DR,
        github:"https://github.com/ACEUS99/Dice-Roll",
        live:"https://diceroll-scrimba.netlify.app",
    }





]