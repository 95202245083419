import React from 'react';

import HTML from '../assets/OtherAssets/html.png';
import CSS from '../assets/OtherAssets/css.png';
import JavaScript from '../assets/OtherAssets/javascript.png';
import ReactImg from '../assets/OtherAssets/react.png';
import Node from '../assets/OtherAssets/node.png';
import FireBase from '../assets/OtherAssets/firebase.png';
import GitHub from '../assets/OtherAssets/github.png';
import Tailwind from '../assets/OtherAssets/tailwind.png';


const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen bg-[#000000] text-gray-300 skills_component'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full '>
          <div>
              <p className='text-4xl font-bold inline border-b-4 border-sky-500 '>Skills</p>
              <p className='py-4'>Hover Over Them!</p>
          </div>

          <div  className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
              <div className='shadow-md shadow-[#040c16] card'>
                  <img className='w-20 mx-auto card__icon' src={HTML} alt="HTML icon" />
                  <p className='my-4'>HTML</p>
              </div>
              <div className='shadow-md shadow-[#040c16]  card'>
                  <img className='w-20 mx-auto card__icon' src={CSS} alt="HTML icon" />
                  <p className='my-4'>CSS</p>
              </div>
              <div className='shadow-md shadow-[#040c16]  card'>
                  <img className='w-20 mx-auto card__icon' src={JavaScript} alt="HTML icon" />
                  <p className='my-4'>JAVASCRIPT</p>
              </div>
              <div className='shadow-md shadow-[#040c16]  card'>
                  <img className='w-20 mx-auto card__icon' src={ReactImg} alt="HTML icon" />
                  <p className='my-4'>REACT</p>
              </div>
              <div className='shadow-md shadow-[#040c16]  card'>
                  <img className='w-20 mx-auto card__icon' src={GitHub} alt="HTML icon" />
                  <p className='my-4'>GITHUB</p>
              </div>
              <div className='shadow-md shadow-[#040c16]  card'>
                  <img className='w-20 mx-auto card__icon' src={FireBase} alt="HTML icon" />
                  <p className='my-4'>FIREBASE</p>
              </div>
            </div>
              
      </div>
    </div>
  );
};

export default Skills;
