import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Link } from 'react-scroll';

function handleClick(){
  
  <Link to='work' smooth={true} duration={500}> </Link>

}

const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-[#000000] home'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <p className='text-[#00bfffe3]'>Hi, my name is</p>
        <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>
          Aaman Kudhail
        </h1>
        <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>
          I'm a Junior Front-end Developer.
        </h2>
        <p className='text-[#8892b0] py-4 max-w-[700px]'>
          My main goal when creating websites is to increase the overall user-experience through robust functionality complimented with visually aesthetic designs.
        </p>
        <div>
          
        </div>
      </div>
    </div>
  );
};

export default Home;
