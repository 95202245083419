import React from 'react';
import aaman from '../assets/OtherAssets/aaman_selfie.JPG';

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#000000] text-gray-300 about_component'>
      <div className='about_div_1 flex flex-col justify-items-center items-center h-full w-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8 about_outer'>
          <div className='sm:text-right pb-8 pl-4 w-full' >
            <p className='about_me_text text-4xl font-bold inline border-b-4 border-sky-500 '>
              About
            </p>
          </div>
          <div></div>
          </div>
          <div className='about__me--container'>
            <div className='aaman__selfie--container'>
              <img className='aaman__selfie' src={aaman}></img>
            </div>
            <div className='max-w-lg about__me--text'>
              <p>I am a graduate from the University of Warwick, where I studied BSc Discrete Mathematics. Since finishing,
                I have enrolled myself in a front-end developer course with Scrimba, where I am learning new skills and honing my current ones.  
              </p>
              <br />
              <p>My interests span the following: software engineering, financial technology & its applications and also cyber security. My reasoning behind these specific divisions/areas is because I am genuinely fascninated by them all and hope to experience what it is like to work within each one!  </p>  
            </div>
          </div>
      </div>
    </div>
  );
};

export default About;
